import React from "react"

//CSS
import styles from '../pages/index.module.scss';
import Head from '../components/head';

//HOC
import Layout from "../layouts/subpage_layout"
import BreadcrumbsLayout from '../layouts/breadcrumbs_layout';

//GRAPHQL
import { graphql } from "gatsby"


export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        mainpage
      }
      fields {
        slug
      }
      html
    }
  }
`

const staticTemplate = props => {
  return (
    <Layout>
      <Head title={props.data.markdownRemark.frontmatter.title} />
      <BreadcrumbsLayout menuItems={[{name: props.data.markdownRemark.frontmatter.mainpage, path: '/'}, {name: props.data.markdownRemark.frontmatter.title}]}>
        <div className={styles.textContentContainer} dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html }}></div>
      </BreadcrumbsLayout>
    </Layout>
  )
}

export default staticTemplate
